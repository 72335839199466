import React from "react";
import Lowerfooter from "./FooterComponent/lowerfooter";
import Topfooter from "./FooterComponent/Topfooter";

// import { Link } from "react-router-dom";
// import Images from "../../All_Images/Images";
// import "./Footer.css";

const Footer = () => {
  return (
    <>
      <Topfooter />

      <Lowerfooter />
    </>
  );
};
export default Footer;
